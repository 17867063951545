$red-color: #f50107;
$purple-color: #800870;
$blue-color: #1105f4;
$dark-blue-color: #1f094b;
$whilte-color: #fff;

// Bootstrap breakpoints
$smallBreakPoint: 576px;
$largeBreakPoint: 992px;

$contentWidth: 1200px;


:root {
    --bs-body-bg: #000;
}
