@import 'bootstrap/dist/css/bootstrap.min.css';

@import './styles/buttons.scss';
@import './styles/form.scss';
@import './styles/variable.scss';
@import './styles/fonts.scss';
@import './styles/input.scss';

* {
  font-family: JosefinSans, Arial, serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  background: black;
  color: $whilte-color;
}

h1 {
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;

  @media (max-width: 500px) {
    font-size: 30px;
  }
}

h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
}

p {
  font-size: 22px;
  line-height: 28px;
}

section {
  z-index: 10;
  position: relative;
}

.content {
  width: 100%;
  max-width: $contentWidth;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullet {
  background-color: white;
  height: 22px;
  width: 22px;
}

.playable-modal {
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);

  .modal-header {
    border: 0;
    filter: invert(1); // invert color of close button to white
  }
}

.content-layer {
  background: linear-gradient(180.1deg, rgba(0, 0, 0, 0) -6.52%, #000000 68.65%);
  position: relative;
  overflow: hidden;
}

.bg-wrapper {
  position: absolute;
  max-width: 100%;
  top: 180px;

  .bg-layer {
    position: relative;
    .icon1,
    .icon2 {
      width: 100%;
    }
    .icon2 {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }

    .gradient {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 60%;

      background: linear-gradient(180.1deg, rgba(0, 0, 0, 0) -6.52%, #000000 68.65%);
    }
  }
  .bg-layer2 {
    position: relative;
    max-width: 100%;
    svg {
      max-width: 100%;
    }
    .gradient {
      max-width: 100%;
      position: absolute;
      width: 600px;
      height: 600px;
      top: 0;
      left: 0;
      transform: rotate(-180deg);
      background: linear-gradient(219.86deg, rgba(0, 0, 0, 0) 12.24%, #000000 63.12%);
    }
  }
}

.bg-bottom-layer {
  position: absolute;
  width: 100vw;
  max-width: 100%;
  bottom: 180px;
  svg {
    width: 100%;
  }

  .bg-layer {
    position: relative;
    .vector1 {
      position: absolute;
    }
    .gradient {
      position: absolute;
      width: 100%;
      height: 1000px;
      top: 0;
      background: linear-gradient(180deg, #000000 28.94%, rgba(0, 0, 0, 0) 96.8%);
    }
  }
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.animation.collaborators-section .button-list {
  animation-name: example;
  animation-duration: 1s;
}

.section-spacing {
  margin-top: 150px;
}

@keyframes example {
  0% {
    padding-top: 500px;
  }
  100% {
    padding-top: 0px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  -webkit-text-fill-color: white !important;
}
