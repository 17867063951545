input,
select {
  background-color: transparent;
  border: 1px solid $purple-color;
  margin-bottom: 20px;
  padding: 24px 20px;
  border-radius: 10px;
  color: white;
  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  @media (max-width: 500px) {
    padding: 10px 14px;
  }
}

select {
  background: url(../assets/images/icon_arrow.png) no-repeat;
  background-position: right 20px center; /* Change the px value here to change the distance */
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &.unselected {
    color: rgba(255, 255, 255, 0.4);
  }
}
