@import './variable.scss';

.support-btn {
  width: 350px;
  height: 200px;
  border-radius: 5px;
  background: radial-gradient(64.08% 64.08% at 49.85% 28.16%, #800870 0%, #1f094b 100%);

  .inner-layer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;

    background: radial-gradient(64.08% 64.08% at 49.85% 28.16%, rgba(5, 15, 244, 0.3) 0%, rgba(31, 9, 75, 0.3) 100%);
  }
}

.collaborator-btn {
  width: 340px;
  height: 175px;
  border-radius: 5px;
  background: radial-gradient(64.08% 64.08% at 49.85% 28.16%, rgba(5, 15, 244, 0.3) 0%, rgba(31, 9, 75, 0.3) 100%);

  .inner-layer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: radial-gradient(64.08% 64.08% at 49.85% 28.16%, rgba(5, 15, 244, 0.3) 0%, rgba(31, 9, 75, 0.3) 100%);

    img {
      max-width: 196px;
      max-height: 85px;
    }
  }
}

.app-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid $blue-color;
  padding: 78px 26px;
  width: 480px;
  height: 450px;
  background-color: transparent;
  color: $whilte-color;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.4em;
  text-decoration: none;

  @media (max-width: 500px) {
    padding: 0;
    width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .divider {
    width: 62px;
    margin-top: 60px;
    margin-bottom: 40px;
    border: 1px solid $blue-color;
  }

  &.entertainment {
    img {
      width: 148px;
      height: 148px;
    }
  }

  &.gaming {
    img {
      margin: auto;
      width: 340px;
      height: 100px;

      @media (max-width: 500px) {
        width: 180px;
        height: auto;
      }
    }
  }

  &:hover {
    background: radial-gradient(64.08% 64.08% at 49.85% 28.16%, rgba(5, 15, 244, 0.4) 0%, rgba(31, 9, 75, 0.4) 100%);
  }
}

.action-button {
  background: transparent;
  color: white;
  font-size: 16px;
  padding: 20px 30px;
  background: linear-gradient(90deg, #311667 0%, #51095f 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  display: block;
  cursor: pointer;
  min-width: 250px;

  @media (max-width: $largeBreakPoint) {
    padding: 20px 10px;
  }

  @media (max-width: $smallBreakPoint) {
    width: 100%;
  }

  &.small {
    font-size: 14px;
    padding: 10px;
  }

  &.learn-button {
    color: white;
    background: transparent;
    border: 1px solid white;
  }
}
